<template>
  <!--  口座開設者情報表示 -->
  <b-card no-body class="mb-5">
    <b-card-body class="bg-light">
      <b-form-row class="mb-3">
        <b-col>
          <p class="lead">
            {{ accountInfo.plrId }} {{ accountInfo.surname }}
            {{ accountInfo.firstname }}/ {{ accountInfo.surnameKana }}
            {{ accountInfo.firstnameKana }}
          </p>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="2">
          <base-input
            v-bind:value="accountInfo.year"
            label="生年"
            id="select-year-of-birth"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="2">
          <base-input
            v-bind:value="accountInfo.genderText"
            label="性別"
            id="select-gender"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            v-bind:value="accountInfo.address"
            label="住所"
            id="select-address"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            v-bind:value="accountInfo.phoneNumber"
            label="連絡先電話番号"
            id="select-phone-number"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="4">
          <base-input
            v-bind:value="accountInfo.email"
            label="メールアドレス"
            id="select-email"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            v-bind:value="accountInfo.receptionType"
            label="コイン受取方法"
            id="select-reception-type"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="4" offset-md="3" md="3" offset-lg="6" lg="2">
          <b-button
            variant="csub"
            class="w-100 mb-3 mb-sm-0"
            v-on:click="resetPassword()"
          >
            ID/PW情報再発行
          </b-button>
        </b-col>
        <b-col sm="4" md="3" lg="2">
          <b-button
            v-b-modal="'update-modal'"
            variant="csub"
            class="w-100 mb-3 mb-sm-0"
            v-on:click="openUpdate()"
          >
            PLR+情報編集
          </b-button>
          <div v-if="isUpdateModalActive">
            <update-modal
              v-bind:account-info="accountInfo"
              v-on:close-modal="closeUpdate($event)"
            />
          </div>
        </b-col>
        <b-col sm="4" md="3" lg="2">
          <message-button
            v-bind:target-info="accountInfo"
            v-bind:target="3"
            v-on:message-sent="$emit('message-sent')"
          ></message-button>
        </b-col>
      </b-form-row>
      <div v-if="isProcessing" class="text-csub text-center">
        <b-icon icon="circle-fill" animation="throb"></b-icon>
        ID/PW情報再発行中です。
      </div>
      <b-alert
        v-if="passwordReset"
        show
        variant="csub"
        class="mt-3"
        dismissible
      >
        パスワード再設定/メール送信されました。
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import MessageButton from '@/components/common/MessageButton'
import UpdateModal from '@/components/account-opener/UpdateModal'
export default {
  props: ['accountInfo'],
  components: {
    BaseInput,
    MessageButton,
    UpdateModal,
  },
  data() {
    return {
      passwordReset: false,
      isProcessing: false,
      isUpdateModalActive: false,
    }
  },
  watch: {
    'accountInfo.plrId'(val) {
      console.log('** watch plr_id **', val)
      this.passwordReset = false
    },
  },
  methods: {
    resetPassword() {
      // パスワード再設定, メール送信
      console.log('** reset password **', this.accountInfo.plrId)
      if (!this.accountInfo.email) {
        alert(
          'メールアドレスの設定がありません。\nパスワード再発行にはメールアドレスが必要です。'
        )
        return
      }
      const result = confirm(
        this.accountInfo.plrId +
          this.accountInfo.surname +
          this.accountInfo.firstname +
          '\nのパスワードを再設定しMYPLR初回ログイン用URLをメール送信します。\nよろしいですか？'
      )
      if (result) {
        this.isProcessing = true
        this.passwordReset = false
        this.$axiosToken
          .post('/api/reset_password', {
            plrId: this.accountInfo.plrId,
          })
          .then((response) => {
            console.log(response)
            this.passwordReset = true
          })
          .catch((error) => {
            console.log(error)
            if (error.response) {
              console.log(error.response)
              alert(
                'ID/PW情報の再発行に失敗しました。\nエラー: ' +
                  error.response.data.detail
              )
            } else {
              alert('ID/PW情報の再発行に失敗しました。')
            }
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    },
    openUpdate() {
      console.log('** open update modal **')
      this.isUpdateModalActive = true
    },
    closeUpdate(event) {
      // 編集画面を閉じたとき
      console.log('** close update modal **', event)
      this.isUpdateModalActive = false
      if (event) {
        // 更新していれば親へ通知
        this.$emit('info-updated', event)
      }
    },
  },
}
</script>
