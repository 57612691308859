<template>
  <!-- 本人性確認書類の閲覧Card -->
  <b-card no-body class="mb-5">
    <b-card-body>
      <b-card-title title-tag="h3">本人性確認書類の閲覧</b-card-title>
      <b-button
        variant="outline-caccent"
        v-on:click="showing ? (showing = false) : (showing = true)"
        class="mb-3"
      >
        {{ showing ? '閉じる ' : '閲覧する' }}
      </b-button>
      <div v-if="showing">
        <b-form-group>
          <b-form-radio
            v-model="selectedDocument"
            name="selected-document"
            value="1"
            class="mr-2"
            button
            button-variant="outline-csub"
            v-bind:disabled="!document1.name"
            >本人性確認書類1</b-form-radio
          >
          <b-form-radio
            v-model="selectedDocument"
            name="selected-document"
            value="2"
            button
            button-variant="outline-csub"
            v-bind:disabled="!document2.name"
            >本人性確認書類2</b-form-radio
          >
        </b-form-group>
        <b-card no-body v-if="ekycPlan">
          <b-card-body>
            <b-card-title title-tag="h3">{{ document1.name }}</b-card-title>
            <b-card-text v-if="ekycPlan == 1" class="text-csub lead">
              公的個人認証
            </b-card-text>
            <b-card-text v-else class="text-csub lead">
              eKYC「ホ」方式
            </b-card-text>
          </b-card-body>
        </b-card>
        <b-card no-body v-else>
          <b-card-body v-if="selectedDocument == 1">
            <div v-if="document1.name">
              <b-card-title title-tag="h3">{{ document1.name }}</b-card-title>
              <b-form-row>
                <b-col>
                  <b-img
                    v-bind:src="document1.front"
                    alt="identification-img11"
                    center
                    thumbnail
                    v-on:click="openZoom(document1.front, document1.back)"
                  />
                </b-col>
                <b-col v-if="document1.back">
                  <b-img
                    v-bind:src="document1.back"
                    alt="identification-img12"
                    center
                    thumbnail
                    v-on:click="openZoom(document1.back, document1.front)"
                  />
                </b-col>
                <b-col v-else class="text-center">裏面なし</b-col>
              </b-form-row>
            </div>
            <div v-else>本人性確認書類が存在しません。</div>
          </b-card-body>
          <b-card-body v-else>
            <b-card-title title-tag="h3">{{ document2.name }}</b-card-title>
            <b-form-row>
              <b-col md="6">
                <b-img
                  v-bind:src="document2.front"
                  alt="identification-img21"
                  center
                  thumbnail
                  v-on:click="openZoom(document2.front, document2.back)"
                />
              </b-col>
              <b-col md="6" v-if="document2.back">
                <b-img
                  v-bind:src="document2.back"
                  alt="identification-img22"
                  center
                  thumbnail
                  v-on:click="openZoom(document2.back, document2.front)"
                />
              </b-col>
              <b-col v-else md="6" class="text-center">裏面なし</b-col>
            </b-form-row>
          </b-card-body>
        </b-card>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { openZoom } from '@/mixins/utility'
export default {
  props: ['document1', 'document2', 'ekycPlan'],
  mixins: [openZoom],
  data() {
    return {
      selectedDocument: 1,
      showing: false,
    }
  },
  watch: {
    document1() {
      this.selectedDocument = 1
      this.showing = false
    },
  },
}
</script>
