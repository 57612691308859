<template>
  <!-- 口座開設者管理 -->
  <base-layout v-bind:title="title">
    <!-- PLR基盤との同期 -->
    <template v-slot:pxr>
      <sync-pxr
        v-bind:is-processing="isPxrProcessing"
        v-bind:sync-date="pxrSyncDate"
        v-on:sync-click="syncPxrData()"
      />
    </template>
    <!-- 検索 -->
    <template v-slot:search>
      <search-form
        v-bind:providers="providers"
        v-bind:disabled="isPxrProcessing"
        v-on:search-click="getSearchResult"
      />
    </template>
    <!-- 検索結果一覧 -->
    <template v-slot:list>
      <b-form-checkbox
        v-model="plrPlusOnly"
        class="box-large text-csub mb-3"
        name="plr-plus-only"
      >
        PLR+で登録した開設者のみ表示する
      </b-form-checkbox>
      <search-result
        v-bind:fields="accountListFields"
        v-bind:items="accountListFiltered"
        v-bind:message="listMessage"
        v-bind:target="pageId"
        v-bind:default-sort="defaultSort"
        v-on:row-selected="getDetail($event, (isUpdate = false))"
      />
    </template>
    <!-- 詳細 -->
    <template v-slot:detail v-if="accountSelected">
      <!-- 情報 -->
      <information
        v-bind:account-info="accountInfo"
        v-on:message-sent="getDetail(accountSelected, (isUpdate = true))"
        v-on:info-updated="
          getDetail(accountSelected, (isUpdate = true), (setListItems = true))
        "
      />
      <!-- 本人性確認書類閲覧 -->
      <view-documents
        v-bind:document1="document1"
        v-bind:document2="document2"
        v-bind:ekyc-plan="ekycPlan"
      />
      <!-- wf一覧・連携 -->
      <b-card no-body class="mb-5">
        <b-card-body>
          <b-card-title title-tag="h3"> ワークフロー連携 </b-card-title>
          <base-table v-bind:items="wfList" v-bind:fields="wfListFields" />
          <p v-if="wfList.length == 0" class="text-center text-caccent">
            ワークフローがありません。
          </p>
        </b-card-body>
      </b-card>
      <!-- メッセージ履歴 -->
      <message-list v-bind:messages="messages" />
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/common/BaseLayout'
import SearchForm from '@/components/account-opener/SearchForm'
import SearchResult from '@/components/common/SearchResult'
import Information from '@/components/account-opener/Information'
import BaseTable from '@/components/common/BaseTable'
import MessageList from '@/components/common/MessageListCard'
import SyncPxr from '@/components/common/SyncPxrData'
import ViewDocuments from '@/components/account-opener/ViewDocuments'
import textGettable from '@/mixins/textGettable'
import { convertTimezone, getPxrSyncDate } from '@/mixins/utility'
export default {
  components: {
    BaseLayout,
    SearchForm,
    SearchResult,
    Information,
    BaseTable,
    MessageList,
    SyncPxr,
    ViewDocuments,
  },
  mixins: [convertTimezone, textGettable, getPxrSyncDate],
  data() {
    return {
      title: '口座開設者',
      pageId: 3,
      // ***** 検索リスト選択用 *****
      providers: [],
      // ***** 検索結果データ口座開設者一覧 *****
      plrPlusOnly: false,
      accountList: [],
      accountListFiltered: [],
      accountListFields: [
        { key: 'check', label: '', tdClass: 'text-center' },
        { key: 'plrId', label: 'PLR ID' },
        { key: 'surname', label: '姓' },
        { key: 'firstname', label: '名' },
        { key: 'surnameKana', label: '姓かな' },
        { key: 'firstnameKana', label: '名かな' },
        { key: 'year', label: '生年', tdClass: 'text-center' },
        { key: 'genderText', label: '性別', tdClass: 'text-center' },
        { key: 'address', label: '住所' },
        { key: 'phoneNumber', label: '連絡先電話番号', tdClass: 'text-center' },
        {
          key: 'dateOfOpeningLocal',
          label: '開設日',
          tdClass: 'text-center',
        },
        { key: 'email', label: 'メールアドレス' },
        { key: 'button', label: '連絡', tdClass: 'text-center' },
      ],
      defaultSort: { by: 'dateOfOpening', desc: true },
      accountSelected: null,
      listMessage: null,
      // ***** 選択口座開設者詳細 *****
      // 口座開設者情報
      accountInfo: {
        id: null,
        plrId: null,
        surname: null,
        firstname: null,
        surnameKana: null,
        firstnameKana: null,
        year: null,
        genderText: null,
        address: null,
        phoneNumber: null,
        email: null,
        receptionType: null,
      },
      // 本人性確認書類
      document1: {},
      document2: {},
      ekycPlan: null,
      // wf連携データ
      wfList: [],
      wfListFields: [
        { key: 'workflowCode', label: 'WfCode' },
        { key: 'name', label: 'ワークフロー' },
        { key: 'providerActorCode', label: 'ActorCode' },
        {
          key: 'providerName',
          label: 'ワークフロープロバイダー',
          class: 'cell-break',
        },
        { key: 'subjectId', label: '被験者ID' },
        { key: 'dateOfLinkage', label: '被験者連携', tdClass: 'text-center' },
      ],
      // メッセージ履歴
      messages: [],
      // PLR基盤との同期
      isPxrProcessing: false,
      pxrSyncDate: null,
    }
  },
  created() {
    // 最終同期日時取得
    this.getPxrSyncDate(this.pageId).then((ret) => {
      this.pxrSyncDate = this.convertTimezone(ret, false)
    })
    // 検索リスト項目取得
    this.getSearchSelectItems()
  },
  watch: {
    plrPlusOnly(filterOn) {
      console.log('** plrPlus filter **', filterOn)
      if (filterOn) {
        this.accountListFiltered = this.accountList.filter(
          (item) => item.openedByPlrPlus
        )
      } else {
        this.accountListFiltered = this.accountList
      }
    },
  },
  methods: {
    syncPxrData() {
      // PLR基盤とのデータ同期
      console.log('** pxr data sync **')
      this.isPxrProcessing = true
      // 表示されているものはクリア
      this.providers = []
      this.accountList = []
      this.accountListFiltered = []
      this.accountSelected = null

      this.$axiosToken
        .post('/api/update/all')
        .then((response) => {
          console.log(response)
          // 同期日時取得
          this.getPxrSyncDate(this.pageId).then(
            (ret) => (this.pxrSyncDate = this.convertTimezone(ret, false))
          )
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('PLR基盤からのデータ取得に失敗しました。')
          }
        })
        .finally(() => {
          this.isPxrProcessing = false
          // 検索リスト選択項目取得
          this.getSearchSelectItems()
        })
    },
    getSearchSelectItems() {
      // 検索でリスト選択項目取得
      this.$axiosToken
        .get('/api/choices_wf_providers')
        .then((response) => {
          console.log(response)
          this.providers = response.data.providers
          for (let i = 0; i < this.providers.length; i++) {
            this.providers[i].name =
              this.providers[i].actorCode + ' ' + this.providers[i].name
          }
          this.providers.unshift({ id: null, name: '' })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSearchResult(searchItems) {
      // 口座開設者一覧取得
      console.log('searchItems', searchItems)
      this.plrPlusOnly = false
      this.listMessage = null
      this.$axiosToken
        .get('/api/account_openers', {
          params: {
            plr_id: searchItems.plrId,
            surname: searchItems.surname,
            firstname: searchItems.firstname,
            surname_kana: searchItems.surnameKana,
            firstname_kana: searchItems.firstnameKana,
            provider_id: searchItems.providerId,
            start: searchItems.openDateFrom,
            end: searchItems.openDateTo,
          },
        })
        .then((response) => {
          console.log('** account_openers list **', response)
          this.accountList = response.data.accountList
          for (let i = 0; i < this.accountList.length; i++) {
            // 日付項目をLocalTimeに変換
            this.accountList[i].dateOfOpeningLocal = this.convertTimezone(
              this.accountList[i].dateOfOpening
            )
            // code取得を画面表示用テキストに変換
            this.accountList[i].genderText = this.getText(
              'gender',
              this.accountList[i].gender
            )
          }
          if (this.accountList.length == 0) {
            this.listMessage = '該当する口座開設者がありません。'
          }
          this.accountListFiltered = this.accountList
        })
        .catch((error) => {
          console.log(error)
          this.accountList = []
          this.accountListFiltered = []
          this.listMessage = '口座開設者の取得に失敗しました。'
        })
    },
    getDetail(selectedRow, isUpdate, setListItems = false) {
      // 選択された口座開設者の詳細取得 isUpdate true:更新 false:行選択で新たに取得
      // setListItems=true:一覧を再取得せず表示の値を更新しておく
      if (!isUpdate && !selectedRow) {
        this.accountSelected = null
        console.log('*** 選択解除')
        return
      }
      // -- 口座開設者詳細get --
      this.$axiosToken
        .get('/api/account_openers/' + selectedRow['id'])
        .then((response) => {
          console.log('** get account detail **', response)
          this.accountInfo.id = response.data.id //info
          this.accountInfo.plrId = response.data.plrId //info
          this.accountInfo.surname = response.data.surname //info
          this.accountInfo.firstname = response.data.firstname //info
          this.accountInfo.surnameKana = response.data.surnameKana //info
          this.accountInfo.firstnameKana = response.data.firstnameKana //info
          this.accountInfo.year = response.data.year //info
          this.accountInfo.genderText = this.getText(
            'gender',
            response.data.gender
          ) //info
          this.accountInfo.address = response.data.address //info
          this.accountInfo.phoneNumber = response.data.phoneNumber //info
          this.accountInfo.email = response.data.email //info
          this.accountInfo.receptionTypeCode = response.data.receptionTypeCode //info
          this.accountInfo.receptionType = response.data.receptionType //info
          this.document1 = response.data.document1 //document
          this.document2 = response.data.document2 //document
          this.ekycPlan = response.data.ekycPlan //document
          this.wfList = response.data.wfProviderList //wf
          // 日付項目をLocalTimeに変換
          for (let i = 0; i < this.wfList.length; i++) {
            this.wfList[i].dateOfLinkage = this.convertTimezone(
              this.wfList[i].dateOfLinkage
            )
            this.wfList[i].dateOfConsent = this.convertTimezone(
              this.wfList[i].dateOfConsent
            )
          }
          // this.requestList = response.data.orderList //customer reques 機能OFF
          this.messages = response.data.messageLog //message
          // 日付項目をLocalTimeに変換
          for (let i = 0; i < this.messages.length; i++) {
            this.messages[i].date = this.convertTimezone(
              this.messages[i].date,
              false
            )
          }
          if (!isUpdate) {
            this.accountSelected = selectedRow
          }
          if (setListItems) {
            const targetRow = this.accountListFiltered.find(
              (r) => r.id === this.accountInfo.id
            )
            targetRow['email'] = this.accountInfo.email
          }
        })
        .catch((error) => {
          console.log(error)
          // 詳細表示ありで行選択でエラーの場合はクリア 更新の時は表示したままにする
          if (isUpdate) {
            alert('口座開設者詳細の更新に失敗しました。')
          } else {
            this.accountSelected = null
            alert('口座開設者詳細の取得に失敗しました。')
          }
        })
    },
  },
}
</script>
