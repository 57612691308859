<template>
  <!--  口座開設者検索フォーム -->
  <b-form v-on:submit.prevent="onSearchClick()">
    <b-form-row>
      <b-col md="2">
        <base-input v-model="search.plrId" label="PLR ID" id="search-plr-id">
        </base-input>
      </b-col>
      <b-col md="2">
        <base-input
          v-model="search.surname"
          label="姓*"
          id="search-surname"
          description="例）山田"
        >
        </base-input>
      </b-col>
      <b-col md="2">
        <base-input
          v-model="search.firstname"
          label="名*"
          id="search-firstname"
          description="例）太郎"
        >
        </base-input>
      </b-col>
      <b-col md="3">
        <base-input
          v-model="search.surnameKana"
          label="姓 かな*"
          id="search-surname-kana"
          description="例）やまだ"
        >
        </base-input>
      </b-col>
      <b-col md="3">
        <base-input
          v-model="search.firstnameKana"
          label="名 かな*"
          id="search-firstname-kana"
          description="例）たろう"
        >
        </base-input>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col lg="8">
        <base-select
          v-model="search.providerId"
          label="ワークフロープロバイダー"
          id="search-wfprovider"
          value-field="id"
          text-field="name"
          v-bind:options="providers"
        >
        </base-select>
      </b-col>
      <b-col md="3" lg="2">
        <base-input
          v-model="search.openDateFrom"
          label="開設日（From）"
          id="search-date-from"
          type="date"
        >
        </base-input>
      </b-col>
      <b-col md="3" lg="2">
        <base-input
          v-model="search.openDateTo"
          label="開設日（To）"
          id="search-date-to"
          type="date"
        >
        </base-input>
      </b-col>
    </b-form-row>
    <!-- 機能OFF -->
    <!-- <b-form-row>
      <b-col>
        <b-form-checkbox
          v-model="search.pendingApprovalsOfUse"
          class="box-large"
        >
          購入要求許諾待ちあり
        </b-form-checkbox>
      </b-col>
    </b-form-row> -->
    <b-form-row>
      <b-col offset-sm="4" sm="4" offset-lg="5" lg="2">
        <b-button
          type="submit"
          variant="csub"
          class="w-100"
          v-bind:disabled="disabled"
        >
          <b-icon icon="search"></b-icon>
          検索
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import BaseSelect from '@/components/common/BaseSelect'
export default {
  components: {
    BaseInput,
    BaseSelect,
  },
  props: ['providers', 'disabled'],
  data() {
    return {
      search: {
        plrId: null,
        surname: null,
        firstname: null,
        surnameKana: null,
        firstnameKana: null,
        providerId: null,
        openDateFrom: null,
        openDateTo: null,
        // pendingApprovalsOfUse: false, //機能OFF
      },
    }
  },
  methods: {
    onSearchClick() {
      this.$emit('search-click', this.search)
    },
  },
}
</script>
