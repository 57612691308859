<template>
  <!-- 個人情報更新モーダル画面 -->
  <b-modal
    id="update-modal"
    size="md"
    title="PLR+情報更新"
    header-bg-variant="cmain"
    header-text-variant="white"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
  >
    <b-alert v-if="outputMsg" show variant="caccent">
      {{ outputMsg }}
    </b-alert>
    <p class="text-csub">
      「{{ accountInfo.plrId }} {{ accountInfo.surname
      }}{{ accountInfo.firstname }}」の情報を更新します。
    </p>
    <p v-if="getListErr" class="text-danger">
      *コイン受取方法リストの取得に失敗しました。
    </p>
    <b-form v-on:submit.prevent="updateInfo()">
      <base-input v-model="email" label="メールアドレス" id="email">
      </base-input>
      <base-select
        v-model="receptionType"
        label="コイン受取方法"
        id="reception-type"
        value-field="code"
        text-field="name"
        v-bind:options="receptionTypeList"
      >
      </base-select>
      <b-form-row>
        <b-col offset-sm="3" sm="3">
          <b-button
            class="w-100 mb-3"
            variant="caccent"
            type="submit"
            v-bind:disabled="isUpdated"
          >
            更新
          </b-button>
        </b-col>
        <b-col sm="3">
          <b-button v-on:click="onCloseClick" class="w-100 mb-3" variant="csub">
            閉じる
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </b-modal>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import BaseSelect from '@/components/common/BaseSelect'
export default {
  components: {
    BaseInput,
    BaseSelect,
  },
  props: ['accountInfo'],
  data() {
    return {
      email: this.accountInfo.email,
      receptionType: this.accountInfo.receptionTypeCode,
      receptionTypeList: [],
      isUpdated: false,
      outputMsg: null,
      getListErr: false,
    }
  },
  created() {
    this.getListItems()
  },
  methods: {
    getListItems() {
      // コイン受取方法リストを取得
      console.log('* get reception_type *')
      this.$axios
        .get('/account_opening/reception_type')
        .then((response) => {
          console.log(response)
          this.receptionTypeList = response.data.receptionTypeList
        })
        .catch((error) => {
          console.log(error)
          this.getListErr = true
        })
    },
    updateInfo() {
      // 口座開設者情報更新
      this.$axiosToken
        .put('/api/account_openers/' + this.accountInfo.id, {
          email: this.email,
          receptionType: this.receptionType,
        })
        .then((response) => {
          console.log('* update info *', response)
          this.outputMsg = '更新が完了しました。'
          this.isUpdated = true
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            this.outputMsg = error.response.data.detail
          } else {
            this.outputMsg = '更新に失敗しました。'
          }
        })
    },
    onCloseClick() {
      // 画面を閉じるとき更新したか返す
      this.$emit('close-modal', this.isUpdated)
    },
  },
}
</script>
